/*
 *
 * Global Styles
 *
 **/

	/** 
	 **Transitions *****************************************************/ 

	a,
	button,
	li {
		transition: all .3s !important;
	}

	/**
	 **Headings / Typography ****************************************************/

	.quando-heading {
		.ff-quando;
		font-weight: 500;
		line-height: normal;
	}

	.mont-heading {
		.ff-montserrat;
		font-weight: 700;
		line-height: normal;
	}

	#wpadminbar {
		position: fixed !important;
	}

/****************************************************
 *
 * Support Assets
 *
 * Alphabetical Order
 *
 ****************************************************/

//Wrapper to remove unwanted line breaks
.no-breaks {
	> br {
		display: none;
	}
} 

 	/** 
	 **Brand Divider ****************************************************/
	.brand-divider {
		width: 80px;
		display: block;
		margin: 25px auto;
		height: 3px;
	}

 	/**
 	 **Buttons ******************************************************/
 	.brand-button (@color) {
 		display: inline-block;
		border: 3px solid @color;
		padding: 10px 30px;
		margin: 20px 0;
		color: @color;
		.ff-quando;
		font-size: 1.3em;
		&:hover {
			background-color: @color;
			color: #fff;
			cursor: pointer;
			text-decoration: none;
		}
 	} 
 	.brand-button-navy  	{ .brand-button (@brand-navy); }
 	.brand-button-teal  	{ .brand-button (@brand-teal); }
 	.brand-button-pink  	{ .brand-button (@brand-pink); }
 	.brand-button-purple  { .brand-button (@brand-purple); }
 	.brand-button-green  	{ .brand-button (@brand-green); }
 	.brand-button-orange 	{ .brand-button (@brand-orange); }
 	.brand-button-blue   	{ .brand-button (@brand-blue); }
 	.brand-button-aqua   	{ .brand-button (@brand-aqua); }
 	.brand-button-yellow  { .brand-button (@brand-yellow); }
 	.brand-button-twitter { .brand-button (@color-twitter); }
 	.brand-button-fb			{ .brand-button (@color-facebook); }

 	.brand-button-alt (@color) {
 		display: inline-block;
		border: 3px solid @color;
		background-color: @color;
		padding: 10px 30px;
		margin: 20px 0;
		color: #fff;
		.ff-quando;
		font-size: 1.3em;
		&:hover {
			background-color: #fff;
			color: @color;
			cursor: pointer;
			text-decoration: none;
		}
 	} 

 	.brand-button-alt-navy  	{ .brand-button-alt (@brand-navy); }
 	.brand-button-alt-teal  	{ .brand-button-alt (@brand-teal); }
 	.brand-button-alt-pink  	{ .brand-button-alt (@brand-pink); }
 	.brand-button-alt-purple  { .brand-button-alt (@brand-purple); }
 	.brand-button-alt-green  	{ .brand-button-alt (@brand-green); }
 	.brand-button-alt-orange 	{ .brand-button-alt (@brand-orange); }
 	.brand-button-alt-blue   	{ .brand-button-alt (@brand-blue); }
 	.brand-button-alt-aqua   	{ .brand-button-alt (@brand-aqua); }
 	.brand-button-alt-yellow  { .brand-button-alt (@brand-yellow); }
 	.brand-button-alt-twitter { .brand-button-alt (@color-twitter); }
 	.brand-button-alt-fb			{ .brand-button-alt (@color-facebook); }

 	button.active-button {
 		background-color: @brand-yellow !important;
 		border-color: @brand-yellow !important;
 		color: #fff !important;
 	}


	/** 
	 **Callout Section
	 **		heading with lead and support paragraph ****************************************************/
	.callout {
		max-width: 900px;
		margin: 0 auto;
		padding: 60px 20px;
		h1 {
			margin: 0px;
		}
		p.primary {
			font-size: 1.5em;
			line-height: 1.7em;
		}
		p {
			margin: 25px 0;
			line-height: 30px;
		}
	}
	@media screen and (max-width: 768px) {
		.callout {
			padding: 20px 15px;
			h1 {
				margin-top: 20px;
			}
		}
	}
	/** end Callout */

	/**
	 **Color Divider ************************************************/
	.brand-section-divider(@bg-color) {
		background-color: @bg-color;
		.ff-quando;
		width: 100%;
		text-align: center;
		font-style: italic;
		color: #fff;
		p {
			font-size: 1.3em;
			font-weight: 700;
			padding: 25px;
			margin: 0px;
		}
		span.flag {
			position: absolute;
			.arrow-down(@bg-color, 20px, 20px);
		}
	}
	.brand-section-divider-orange { .brand-section-divider(@brand-orange); }
	.brand-section-divider-green { .brand-section-divider(@brand-green); }
	.brand-section-divider-blue { .brand-section-divider(@brand-blue); }
	.brand-section-divider-aqua { .brand-section-divider(@brand-aqua); }

	/** 
	 **CTA Blocks****************************************************/
	.cta-block {
		background-color: @brand-blue;
		border: 2px solid #fff;
		border-left: 1px solid #fff;
		border-right: 1px solid #fff;
		text-align: center;
		transition: all .3s;
		&:hover {
			background-color: spin(@brand-blue, 10);
		}
		a {
			width: 100%;
			display: block;
			padding: 20px;
			color: #fff;
			text-align: center;
			text-transform: uppercase;;
			font-size: 20px;
			font-weight: 700;
			&:hover {
				text-decoration: none;
			}
			i {
				font-size: 1.5em;
				line-height: 1em;
				margin-right: 15px;
				top: 4px;
				position: relative;
				color: fade(#fff, 70%);
			}
		}
		// The below throws an error in the minification task
		// &:nth-child[0]{
		// 	border-left: none !important;
		// }
		// &:nth-child[3] {
		// 	border-right: none !important;
		// }
	}
	.cta-block.help {
		background-color: @brand-aqua !important;
		&:hover {
			background-color: spin(@brand-aqua, -10) !important;
		}
	}

	@media screen and (max-width: 1024px) {
		.triage {
			border: 2px solid #fff;
		}
		.cta-block {
			padding: 0;
			height: 80px;
			overflow: hidden;
			border: 3px solid #fff;
			a {
				padding: 10px;
				font-size: 17px;
				i {
					display:block;
					margin-right: 0px;
					margin-bottom: 5px;
				}
			}
		}
	}
	/** end CTA-blocks */

	/**
	 ** Donation Container *********************************************/

	.donation-container {
		background-color: @brand-navy;
		color: #fff;
		text-align: center;
		padding: 60px 20px;
		.lead {
			color: #fff;
		}
		h1 {
			.ff-quando;
			margin: 20px;
		}
		p {
			margin: 20px;
			color: #fff;
		}
		.donation-form {
			width: auto;
			margin: 0 auto;
			position: relative;
			button{
				border-radius: 0px;
				border: 3px solid @brand-yellow;
				padding: 10px 15px;
				margin: 0 5px;
			}
			.amount-wrap {
				display: inline-block;
				.amount {
					background-color: transparent;
					color: #fff !important;
					&:hover {
						background-color: @brand-yellow;
					}
				}
				.selected {
					background-color: @brand-yellow !important;
				}
			}
			.other-wrap {
				display: inline-block;
				position: relative;
				max-width: 100%;
				// overflow: hidden;
				button {
					color: @font-color-light;
					font-size: 1.3em;
					padding: 10px;
					border: none;
					background-color: transparent;
					position: absolute;
					display: inline-block;
				}
				#dollarSign {
					z-index: 1;
					top: -1px;
					.ff-quando;
					left: 4px;
					font-size: 1.7em;
				}
				#otherAmount {
					//width: 507px;
					max-width: 100%;
					position: relative;
					padding: 10px 10px 10px 40px;
					.ff-quando;
					font-size: 1.3em;
					border: 3px solid @brand-yellow;
					color: @font-color-light;
				}
				@media screen and (min-width: 1024px) {
					#otherAmount {
						width: 507px;
					}
				}
				#close {
					right: 4px;
					top: 1px;
					//left: -50px;
					&:hover {
						color: @brand-navy;
						background-color: transparent;
					}
				}
			}
			.submit {
				background-color: @brand-yellow;
				color: @brand-navy;
				padding: 10px 30px;
				&:hover {
					background-color: #fff;
				}
			}
		}
		.after-form	{
			margin-top: 30px;
			text-align: center;
			a {
				display: inline-block;
				color: #fff;

			}
		}
	}
	@media screen and (max-width: 1024px) {
		.donation-container {
			.donation-form {
				.amount-wrap {
					display: inline;
				}
				button {
					font-size: 16px;
					padding: 10px 5px;
					margin: 5px;
				}
				.submit {
					padding: 10px 20px;
				}
			}
		}
	}
	.donation-container.donate-footer {
		width: 100%;
		padding: 20px;
		//height: 200px;
		.wrapper {
			display: table;
			height: 100%;
			margin: 0 auto;
			text-align: center;
		}
		h1 {
			max-width: 300px;
			margin: 20px auto;
		}
		p.lead {
			display: none;
		}
		form {
			padding: 0 20px;
		}
		.after-form {
			
		}
		h1, form, .after-form, p {
			// display: inline-block;
			// &:before {
			// 	content: '';
			// 	height: 100%;
			// 	width: 0;
			// 	display: inline-block;
			// 	vertical-align: middle;
			// }
			display: table-cell;
			vertical-align: middle;
			height: 100%;
		}
	}
	@media screen and (max-width: 768px) {
		.donation-container.donate-footer {
			.wrapper {
				display: block;
			}
			h1, form, .after-form, p {
				display: block;
			}
			form {
				padding: 0;
			}
		}
	}
	/** end Donation Container */

	/**
	 ** Dropdown Sections - (shortcode) ***************************************************/

	 .volunteer .dropdown-section {
	 	border-bottom: 1px solid lighten(#ccc, 15%);
	 	> a {
	 		font-size: 16px;
	 		span {
	 			font-size: 26px;
	 			padding-left: 0px;
	 		}
	 	}
	 	.dropdown-section-content {
	 		//padding: 0px 0px 20px 30px;
	 	}
	 }

	.dropdown-section {
	 	> a {
	 		padding: 0 0 10px;
	 		display: block;
	 		color: @brand-blue;
	 		font-size: 16px;
	 		span {
	 			font-size: 26px;
	 			padding-left: 0px;
	 			position: relative;
	 			top: 4px;
	 			margin-right: 5px;
	 		}
	 		.fa-angle-up {
	 			color: @brand-aqua;
	 			display: none;
	 		}
	 		&:hover,
	 		&:focus,
	 		&:active {
	 			text-decoration: none;
	 		}
	 	}
	 	.dropdown-section-content {
	 		//background-color: @brand-grey;
	 		padding: 0px 0px 20px 0px;
	 		display: none;
	 		> h2 {
	 			margin-top: 0px;
	 			font-weight: 400;
	 		}
	 		img {
	 			display: block;
	 			width: 100%;
	 			height: auto;
	 			padding: 20px 0;
	 		}
	 	}
	 }
	.dropdown-section.open {
	 	> a {
	 		color: @brand-aqua !important;
	 		.fa-angle-up { display: inline-block; }
	 		.fa-angle-down { display: none; }
	 	}
	 	.dropdown-section-content {
	 		display: block;
	 	}
	}

	/** end Dropdown Section*/

	/**
	 ** Mobile Dropwdown - internal page content navigation ***************************************/
	.mobile-dropdown {
		button {
			display: none;
			margin-bottom: 10px;
			color: #fff;
			border: none;
			width: 100%;
			text-align: center;
			font-size: 1.1em;
			padding: 5px;
			//border-radius: 5px; 
		}
		margin-bottom: 20px;
		.dropdown-content {
			// border: 1px solid @font-color-light;
			// padding: 10px;
			//box-shadow: 0px 5px 5px #ccc;
			transition: all .3s;
		}
	}

	@media screen and (max-width: 1024px) {
		.mobile-dropdown {
			button {
				display: block;
			}
			.dropdown-content {
				display: none;
			}
			.dropdown-content.open {
				//display: block !important;
			}
		}
	}

	/** end Mobile Dropdown **/

	/**
	 **Navigation Search Slideout - desktop version ***********************************************/
	.navbar-secondary {
		.searchContainer {
			#searchButton,
			form,
			input {
				display: inline-block;
			}
			#searchButton {
				// padding-right: 10px;
			}
			form {
				// padding-right: 18px;
				input {
					padding-left: 0px;
					background-color: transparent;
					border: none;
					border-bottom: 2px solid @brand-navy;
					color: @brand-navy;
					width: 135px;
				}
			}
			#searchsubmit {
				background-color: transparent;
				border: none;
				width: 0px;
				padding: 0px;
				margin: 0px;
			}
			#hideSearchField {
				background-color: transparent;
				color: @brand-navy;
				border: none;
				padding: 0px;
				margin: 0px;
				&:hover {
					color: @brand-aqua;
				}
			}
			#searchform {
				display: none;
			}
		}
	}


	/** end Search Slideout*/

	/** 
	 **Vertically aligned content - outer container must have calculable height ****************************************************/
	.outer {
		display: table;
		height: 100%;
		width: 100%;
		.inner {
			display: table-cell;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	/** end VA content*/


//Board Styles

.page-template-page-board,
.page-template-page-board-committee {
	.person {
		margin-bottom: 8px;
		div {
			padding-left: 0px;
			padding-right: 0px;
		}
	}
	.documents,
	.minutes {
		ul {
			list-style: none;
			padding-left: 0px;
		}
	}
	.sidebar-content {
		.dropdown-toggle {
			background-color: @brand-navy !important;
		}
		ul {
			list-style: none;
			li {
				margin-bottom: 5px;
				a {
					color: @brand-navy;
				}
			}
			.current_page_item {
				> a {
					color: @brand-aqua;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.sidebar-content {
			padding: 0;
			margin: 0 -15px;
			ul {
				padding-left: 30px;
			}
		}
	}
}

#service-nav {
	scroll-margin-top: 100px;
}