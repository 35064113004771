//admin
#wpAdminToggleButton {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 1000;
	font-size: 10px;
	padding: 10px 15px;
	background-color: #000;
	color: #fff;
	border: none;
	border-top-left-radius: 5px;
	opacity: .75;
	&:hover {
		opacity: 1;
	}
	display: inline-block;
}
.noAdminBar {
	margin-top: -32px !important; //move body up
}
@media screen and (max-width: 768px){
	.noAdminBar {
		margin-top: -47px !important;
	}
}