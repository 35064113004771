//Gravity Forms Style Overrides
.gform_wrapper {
	position: relative;
	.gform_heading {
		display: none;
	}
	//CC Field
	.custom-cc-field {
		.ginput_complex {
			.ginput_full {
				.ginput_cardinfo_left {
					.ginput_card_expiration_container {
						//min-width: 200px !important;
					}
				}
			}
		}
	}
	//Custom Checkboxes / Radio
	.gfield.custom-check-radio {
		> div {
			ul {
				li {
					input {
						display: none;
					}
					label {
						&:before {
							content: '';
							position: absolute;
							width: 20px;
							height: 20px;
							display: inline-block;
							border: 3px solid @brand-navy;
							left: 0px;
							top: 5px;
						}
					}
					input[type=radio]:checked + label,
					input[type=checkbox]:checked + label {
						&:before {
							//content: 'x';
							//border: 6px solid @brand-yellow;
							//color: @brand-navy;
							background-color: @brand-yellow;
						}
					}
				}
			}
		}
	}

	//Donation Amount Buttons
	.gfield.button-select {
		> label {
			display: none;
		}
		.ginput_container_radio {
			ul.gfield_radio {
				li {
					text-transform: capitalize;
					display: inline-block;
					width: auto;
					input[type=radio] {
						display: none;
					}
					input[type=radio]:checked + label {
						color: @brand-navy;
						background-color: @brand-yellow;
						border-color: @brand-yellow;
						font-weight: 400;
					}
					input[type=text] {
						background-color: @brand-navy;
						border: 3px solid @brand-navy;
						color: #fff;
						.ff-quando;
						font-style: normal;
						font-size: 1.3em;
						line-height: normal;
						margin-bottom: 0px !important;
						padding: 18px 20px;
						transition: all .3s;
						//width: auto;
						&:focus {
							width: auto;
						}
					}
					label {
						.ff-quando;
						color: #fff;
						background-color: @brand-navy;
						border: 3px solid @brand-navy;
						padding: 15px 20px;
						margin: 0 10px 0 0;
						display: inline-block;
						cursor: pointer;
						transition: all .3s;
						&:hover {
							background-color: @brand-yellow;
							border-color: @brand-yellow;
						}
					}
				}
			}
		}
	}

	button {
		transition: all .3s;
	}
	.visually-hidden {
		display: none !important;
	}
	input {
		margin-bottom: 20px !important;
	}
	.section-no-title {
		> .gfield_label {
			display: none;
		}
	}
	.section-title-blue {
		> .gfield_label {
			font-weight: 400;
			font-size: 1.4em;
			color: @brand-navy;
		}
	}
	.section-title-large {
		> .gfield_label {
			font-size: 30px;
		}
	}
	.section-width-full {
		width: 100% !important;
		.ginput_container {
			width: 97.5%;
			input {
				width: 100%;
			}
		}
	}
	.section-width-half {
		clear: none !important;
		width: 48.5%;
		margin-right: 1.3%;
		float: left;
		font-style: italic;
		&:first-of-type {
			margin-right: 1.7%;
		}
		label.gfield_label {
			display: none;
		}
		.ginput_container {
			width: 97.5%;
			input {
				width: 100%;
				padding: 15px;
			}
		}
	}
	.gfield.custom-name {
		span {
			margin-right: 15px;
			width: auto !important;
			vertical-align: middle;
			select {
				display: inline-block !important;
				margin-top: 2px;
				margin-bottom: 0px;
			}
		}
		input[type=text]{

		}
	}
	textarea {
		padding: 10px;
		border: 1px solid #ccc;
	}
	.custom-textarea {
		textarea {
			padding: 10px;
			border: 1px solid #ccc;
		}
	}
	.gfield_checkbox {
		li {
			width: 50%;
			display: inline-block;
			margin: 0;
			label {
				font-size: 1.3em;
			}
		}
	}
	.gfield_html {
		width: 100%;
		input[type=text] {
			width: 50%;
			padding: 25px 10px !important;
			border: 2px solid @brand-grey;
		}
		.brand-button-alt-navy {
			padding: 20px 30px;
		}
		.brand-button-alt-navy.selected {
			background-color: @brand-yellow;
			border-color: @brand-yellow;
			color: @brand-navy;
		}
	}
	.gfield_total.custom-total {
		//clear: none;
		margin: 40px 0;
		box-sizing: border-box;
		max-width: 97.5%;
		display: block;
		padding: 15px 10px;
		background-color: @brand-yellow;
		label {
			font-size: 1.2em;
			color: #fff;
		}
		div.ginput_container_total {
			margin-top: 0px;
			display: inline-block;
			float: right;
			clear: both;
			span {
				margin-left: 10px;
				color: @brand-navy;
				font-size: 2em;
			}
		}
	}
	.gfield {
		input[type=text],
		input[type=email] {
			padding: 15px 10px;
			font-style: italic;
		}
		> label.gfield_label {
			//display: none;
		}
		.ginput_container_select {
			//display: inline-block;
		}
		select {
			.form-control;
			height: 54px !important;
			border: 1px solid #ccc !important;
			font-style: italic;
			display: inline-block;
		}
		hr {
			border-color: @brand-grey;
		}
		.ginput_complex {
			> span {
				//float: left;
			}
		}
		.ginput_container {
			span {
				//float: left;
				label {
					display: none;
				}
			}
		}
		.ginput_container_radio {
			ul.gfield_radio {
				width: 100%;
				li {
					width: 50%;
					display: inline-block;
					input {

					}
					label {
						font-weight: 400;
						font-size: 1.3em;
						margin-left: 30px;
					}
				}
			}
		}
	}

	.gform_footer {
		input[type=submit] {
			color: @brand-navy;
			padding: 15px 20px;
			background-color: @brand-yellow;
			border: 3px solid @brand-yellow;
			font-size: 1.3em;
			transition: all .3s;
			.ff-quando;
			&:hover {
				border-color: @brand-navy;
			}
		}
	}
}

.gform_wrapper.gform_validation_error {
	.gform_body {
		ul.gform_fields {
			li.gfield.gfield_error {
				.ginput_container {
					select {
						border-color: #790000 !important;
					}
				}
			}
		}
	}
}

.giving_tuesday_form {
	.gform_wrapper {
		.gf_progressbar_wrapper {
			display: none;
		}
		.gform_body {
			ul.gform_fields {
				width: 100%;
				list-style: none;
				.section-width-half	{
					margin-right: 1.5%;
					.ginput_container {
						input {
							width: 100%;
						}
					}
				}
				.custom-single-product {
					clear: none !important;
					text-align: center;
					padding: 10px;
					// width: 33%;
					// float: left;
					// clear: none;
					// margin: 10px 0;
					label {
						font-size: 1.5em;
						//color: @font-color-light;
					}
					div.gfield_description {
						img {
							width: auto;
							max-width: 70%;
							height: 180px;
							margin-bottom: 10px;
						}
					}
					div.ginput_container {
						span.ginput_product_price_label {
							display: none;
						}
						span.ginput_product_price {
							color: @brand-navy;
							font-size: 1.3em;
						}
						span.ginput_quantity_label {
							color: @font-color-light;
						}
					}
				}
				.gfield_total_old {
					clear: none;
					display: inline-block;
					label {
						font-size: 1.2em;
						color: @font-color-light;
					}
					div.ginput_container_total {
						display: inline-block;
						clear: none;
						span {
							margin-left: 10px;
							color: @font-color;
							font-size: 2em;
						}
					}
				}
			}
		}
		.gform_footer {
			border-top: none !important;
			//margin: 0 !important;
			margin-top: 20px;
			display: block;
			//float: right;
			width: auto;
			clear: both;
			padding-top: 0;
			input[type=submit] {
				color: @brand-navy;
				padding: 15px 20px;
				background-color: @brand-yellow;
				border: 3px solid @brand-yellow;
				font-size: 1.3em;
				transition: all .3s;
				.ff-quando;
				&:hover {
					border-color: @brand-navy;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.gform_wrapper {
		.gfield.button-select {
			.ginput_container_radio {
				ul.gfield_radio {
					li {
						width: 25%;
						padding: 0 3px !important;
						margin-bottom: 2px;
						label {
							text-align: center;
							padding: 5px 0;
							width: 100%;
						}
					}
					li:nth-child(5) {
						width: 100%;
					}
				}
			}
		}
		.gfield {
			.ginput_container_radio {
				ul.gfield_radio {
					li {
						width: 100%;
						label {
							font-size: 1.1em;
							line-height: 1.7;
						}
					}
				}
			}
		}
		.top_label {
			select {
				width: 100% !important;
				height: 40px !important;
			}
		}
		.gfield_checkbox {
			li {
				label {
					font-size: 1.1em;
					line-height: 1.7;
				}
			}
		}
		.gfield {
			input[type=text],
			input[type=email],
			input[type=tel] {
				padding: 10px;
				border: 1px solid #ccc;
				margin-bottom: 10px !important;
			}
		}
		li.gfield {
			//max-width: 100% !important;
			margin-top: 0 !important;
			margin-bottom: 0 !important;
		}
		.gfield_total.custom-total {
			padding: 5px;
			margin-top: 10px !important;
			label { 
				width: 100%;
				float: none;
				margin: 5px 0;
			}
			div.ginput_container_total {
				float: none;
				width: 100%;
				text-align: right;
			}
		}
		.gfield {
			.ginput_container_text {
				input {
					width: 100%;
				}
			}
		}
	}
}

.gform_wrapper {
	.ginput_complex {
		.ginput_full {
			.ginput_cardinfo_left {
				span.ginput_card_expiration_container {
					min-width: 200px !important;
				}
			}
		}
	}
}

//fixing gform update radio box stlying

.gform_wrapper ul.gfield_checkbox li label, .gform_wrapper ul.gfield_radio li label {
	margin-left: 30px;
}