#page-loading {
	position:fixed;
	//z-index:100;
	height:100%;
	width:100%;
	box-sizing:border-box;
	background-color:#fff;
	display:table;
	div {
		display:table-cell;
		vertical-align:middle;
		text-align:center;
		svg {
			width: 400px;
			height: auto;
			g {
				path {
					fill: #888;
				}
			}
		}
	}
}