//Page Home
@media screen and (max-width: 1024px) {
.donation-container{
	padding: 20px;
		.donation-form {
			button { 
				margin: 5px;
			}
		}
		.after-form {
			margin-bottom: 30px;
		}
	}
}

//News 
.news-container {
	padding: 60px 20px;
	.news-feed {
		border-right: 2px solid @brand-grey;
		.heading {
			h1 {
				color: @brand-navy !important;
				.ff-quando;
				font-weight: 400;
			}
		}	
		.news-article {
			padding: 0px 10px 0px 0px;
			h3 {
				color: @brand-blue;
				transition: all .3s;
			}
			.date {
				text-transform: uppercase;
				color: @font-color-light;
			}
			a {
				color: @brand-blue;
				&:hover {
					text-decoration: none;
					color: @font-color-light;
					h3 {
						color: @font-color-light;
					}
				}
			}
		}
	}
	.events-feed {
		padding-top: 20px;
		padding-left: 40px;
		.event-box {
			margin-bottom: 20px;
			height: 150px;
			overflow: hidden;
			background-size: cover;
			background-position: center center;
			color: #fff;
			.ff-montserrat;
			text-align: center;
			span {
				display: block;
			}
			.brand-divider {
				background-color: fade(#fff, 50%);
				height: 4px;
				margin: 10px auto;
			}
			.outer {
				background-color: fade(#000, 30%);
				transition: all .3s;
				&:hover {
					background-color: fade(#000, 70%);
				}
			}
			.inner {
				padding: 20px;
			}
		}
		.style_one {
			.line1 {
				text-transform: uppercase;
				font-size: 2em;
				margin: 0 !important;
			}
			.line2 {
				text-transform: uppercase;
				letter-spacing: 1px;
				font-size: 1.1em;
				margin: 0;
			}
			.line3 {
				margin: 0 !important;
				font-weight: 700;
				font-size: 1.5em;
				line-height: 1em;
			}
		}
		.style_two {
			.line1 {
				text-transform: uppercase;
				font-size: .8em;
				letter-spacing: 1.5px;
			}
			.line2 {
				font-size: 1.8em;
				line-height: 1em;
			}
			.brand-divider {
				display: none;
			}
			.line3 {
				text-transform: uppercase;
				font-size: 1.1em;
				margin-top: 10px;
			}
		}
		.after-feed {
			margin-top: 40px;
		}
	}
	.after-feed {
		margin-top: 30px;
		text-align: center;
		a {
			display: block;
			font-size: 1.3em;
			color: @font-color-dark;
			&:hover {
				text-decoration: none;
				color: @font-color-light;
			}
		}
	}
}


@media screen and (max-width: 1024px) {
	.news-container {
		padding: 20px 0;
		.news-feed {
			border-right: 0px solid #fff;
		}
		.events-feed {
			padding-left: 15px;
			margin-top: 40px;
		}
		.after-feed {

		}
	}
}