nav.navbar-default {
	
	//animations
	* {
    //-webkit-transition-duration: .5s; /* Safari */
    animation-duration: .3s;
	}

	// background-color: #fff;
	button.navbar-toggle {
		margin-top: 15px;
		margin-bottom: 15px;
		background-color: @brand-navy;
		border-color: @brand-navy;
		border-radius: 0px;
		padding: 13px 9px;
		.icon-bar {
			background-color: #fff;
			height: 3px;
			width: 29px;
		}
		&:hover,
		&:active,
		&:focus {
			background-color: @brand-aqua;
			border-color: @brand-aqua;
		}
	}
	.navbar-collapse {
		overflow: scroll;
	}

	.navbar-brand {
		padding: 12px;
		img {
			height: 47px;
		}
		svg {
			height: 50px;
		}
	}
	.hidden-mobile {
		display: none !important;
	}
	.visible-mobile {
		display: block !important;
	}
}

nav.navbar-default.squishy {

}

//Navbar Open on Mobile
nav.navbar-default.navbar-active {
	button.navbar-toggle {

	}
	.navbar-backdrop {
		display: block;
		position: fixed;
		z-index: 0;
		background-color: fade(#fff, 50%);
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
	button.navbar-retract {
		background-color: @brand-aqua;
		color: #fff;
		position: fixed;
		top: 8px;
		left: 8px;
		width: 50px;
		height: 50px;
		display: block !important;
		border: none !important;
		box-shadow: none;
		span {
			font-size: 1.4em;
			top: 3px;
		}
	}
	.navbar-collapse {
		//display: none !important;
		// display: block !important;
		overflow: scroll !important;
		background-color: @brand-navy;
		margin: 0px;
		padding: 0px;
		position: fixed;
		top: 0;
		right: 0;
		width: 80%;
		height: 100% !important;
		border: none !important;
		.navbar-primary {
			margin: 0px;
			padding: 20px 0;
			background-color: @brand-blue;
			li {
				position: relative;
				padding: 10px 25px;
				a {
					color: #fff;
					padding: 5px 10px;
				}
				.mobile-indicators {
					text-align: center;
					background-color: transparent;
					border: none;
					color: #fff;
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					width: 60px;
					font-size: 1.2em;
					line-height: normal;
					z-index: 1;
					padding: 6px;
				}
				ul.navbar-children {
					margin: 20px -25px 0px;
					position: relative;
					top: auto;
					min-width: auto;
					background-color: lighten(@brand-blue, 10%);
					//display: block;
					box-shadow: none;
					li {
						a {
							padding: 3px 12px;
						}
					}
				}
			}
			li.donate {
				padding: 5px 35px;
				background-color: transparent;
				a {
					padding: 15px 10px;
					display: inline-block;
					background-color: @brand-yellow !important;
					color: @brand-navy;
				}
			}
			li.mobileSearch {
				display: block;
				span.fa-search {
					position: absolute;
					top: 18px;
					color: @font-color-light;
				}
				button#search-submit {
					position: absolute;
					top: 16px;
					padding: 0px;
					background-color: transparent;
					border: none;
					color: @font-color-light;
					right: 22px;
				}
				input {
					font-style: italic;
					width: 100%;
					.ff-montserrat;
					color: @font-color-light;
					background-color: transparent;
					border: 0px;
					border-bottom: 2px solid @brand-aqua;
					border-radius: 0px !important;
					font-size: .7em;
					padding: 10px 20px 10px 30px;
				}
			}
		}
		.navbar-secondary {
			background-color: @brand-navy;
			margin: 0px;
			padding: 10px 0;
			li {
				a {
					.ff-montserrat;
					font-size: 14px;
					color: #fff;
					padding: 10px 35px;
					.mobile-indicators {
						display: block;
					}
				}
				ul.navbar-children {
					position: relative;
					top: auto;
					min-width: auto;
					background-color: @brand-aqua;
					//display: block;
					box-shadow: none;
					li {
						a {

						}
					}
				}
			}
			.searchContainer {
				display: none;
			}
		}
	}
	.collapse {
		display: block !important;
	}
	.navbar-brand {

	}
}
.navbar-active {
	.navbar-collapse {
		height: 100% !important;
	}
}

//Mobile freeze layer
#mobileFreeze {
	transition: all .5s;
}
#mobileFreeze.frozen {
	position: fixed;
	width: 100%;
	height: 100%;
	//overflow: hidden;
}


//Collapse Navbar
.navbar-header {
    float: none;
}
.navbar-toggle {
    display: block;
}
.navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
}
.navbar-collapse.collapse {
    display: none!important;
}
.navbar-nav {
    float: none!important;
    margin: 7.5px -15px;
}
.navbar-nav>li {
    float: none;
}
.navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
}
.navbar-text {
    float: none;
    margin: 15px 0;
}
/* since 3.1.0 */
.navbar-collapse.collapse.in { 
    display: block!important;
}
.collapsing {
    overflow: hidden!important;
}
