/**
 ** Rollover-boxes **********************************************/
.rollover-box (@color) {
	padding: 15px;
	height: 300px;
	background-size: cover;
	background-position: center center;
	border: 3px solid @brand-grey;
	text-align: center !important;
	.static {
		position: absolute;
		height: 100%;
		width: 100%;
		background-color: fadeout(#333, 50%);
		h2 {
			font-size: 26px;
			margin: 0 auto;
			max-width: 60%;
			font-weight: 400;
		}
	}
	.hover {
		background-color: @color;
		color: #fff;
		position: absolute;
		height: 100%;
		width: 100%;
		opacity: 0;
		transition: opacity .5s;
		padding: 20px;
	}
	&:hover,
	&:focus,
	&:active {
		.hover {
			opacity: 1;
		}
	}
	.active {
		.hover {
			//opacity: 1;
		}
	}
}
.rollover-box-aqua   { .rollover-box(@brand-aqua); }
.rollover-box-blue   { .rollover-box(@brand-blue); }
.rollover-box-green  { .rollover-box(@brand-green); }
.rollover-box-yellow { .rollover-box(@brand-yellow); }
.rollover-box-orange { .rollover-box(@brand-orange); }
.rollover-box-purple { .rollover-box(@brand-purple); }
.rollover-box-pink   { .rollover-box(@brand-pink); }
.rollover-box-teal   { .rollover-box(@brand-teal); }
.rollover-box-tangerine { .rollover-box(@brand-tangerine); }


//Theme assets
.rollover-box {
	.static {

	}
	.hover {
		.brand-divider {
			background-color: fade(#fff, 70%);
			margin: 15px auto;
			height: 2px;
		}
		a {
			margin-top: 20px;
			display: block;
			color: fade(#fff, 70%);
			font-size: 1.2em;
			&:hover {
				text-decoration: none;
				color: #fff;
			}
		}
	}
}

#no-hover {
	.hover {
		opacity: 1 !important;
	}
}

@media screen and (max-width: 1024px) {
	.rollover-box {
		.hover {
			display: none;
		}
		.active {
			.hover {
				display: block !important;
			}
		}
		&:hover,
		&:focus,
		&:active {
			.hover {
				display: block !important;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.rollover-box {
		height: 150px;
		.static {
			h2 {
				font-size: 16px;
				max-width: 90%;
			}
		}
		.hover {
			font-size: 12px;
			padding: 10px;
			overflow-x: hidden;
			overflow-y: scroll;
			.brand-divider {
				display: none;
			}
			h3 {
				display: none;
				margin-top: 0px;
			}
			a {
				margin-top: 5px;
				font-size: 16px;
				color: #fff;
			}
		}
	}
}