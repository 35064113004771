.hero-video-container {
	position: relative;
	.video-wrapper {
		z-index: 0;
		height: inherit;
		width: 100%;
		overflow: hidden;
		position: absolute;
		video {
			//position: absolute;
			min-width: 100%;
			min-height: 100%;
		}
	}
	.video-content{
		position: absolute;
		width: 100%;
		height: inherit;
		z-index: 2;
		text-align: center;
		display: table;
		padding: 20px;
		.video-inner {
			display: table-cell;
			vertical-align: middle;
			width: 100%;
			h1 {
				font-weight: 300;
				color: #fff;
				font-size: 5em;
			}
			h2 {
				font-weight: 500;
				color: #fff;
			}
			#playModalVideo {
				background-color: transparent;
				border: none !important;
				color: #fff;
				font-size: 3em;
				text-align: center;
				transition: all .5s;
				outline: 0;
				&:hover {
					color: @brand-primary;
				}
				&:hover,
				&:active,
				&:focus {
					outline: 0 !important;
				}
			}
		}
	}
}

.hero-video-modal {
	background-color: #000;
	.modal-dialog {
		display: table;
		height: 100%;
		width: 100%;
		margin: 0 auto;
		.modal-content {
			display: table-cell;
			height: 100%;
			width: 100%;
			vertical-align: middle;
			text-align: center;
			background-color: transparent;
			border: none;
			border-radius: none;
			box-shadow: none;
			padding: 20px;
			button {
				display: block;
				margin: 10px auto;
				border: none;
				background-color: transparent;
				.ff-lato;
				font-size: 3em;
				color: #fff;
				text-align: center;
			}
			.video-container {
				position: relative;
				width: 100%;
				height: 100%;
				padding: 5% 10%;
				margin: 0 auto;
				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
			.close {
				float: none;
				margin: 0 auto;
				transition: all .3s;
				&:hover {
					opacity: 1;
				}
			}
		}
	}
}