// Typography
@font-size: 15px;
@font-line-height: 1.3;
@font-color: #444444;
@font-color-light: #96a0a3;
@font-color-dark: #333333;

// Social Colors
@color-facebook: #3b5998;
@color-twitter: #00aced;
@color-google: #dd4b39;
@color-youtube: #bb0000;
@color-linkedin: #007bb6;
@color-instagram: #517fa4;
@color-flickr: #ff0084;
@color-pinterest: #cb2027;

//LYS
@brand-navy: #223768;
@brand-grey: #f1f1f1;

@brand-aqua: #5dcbef;
@brand-blue: #3860ac;
@brand-green: #a2b93a;
//@brand-yellow: #e2b627;
//@brand-yellow: #edc236;
@brand-yellow: #f0cc01; //consensus
@brand-orange: #dd6042;
@brand-purple: #5a3d98;
@brand-pink: #db3c88;
@brand-teal: #41bb90;
@brand-tangerine: #ed9100;

/*
 * Support */

 //text
.text-white       { color: #fff !important; }
.text-light-grey  { color: @font-color-light !important; }
.text-dark-grey   { color: @font-color-dark !important; }
.text-navy        { color: @brand-navy !important; }
.text-orange      { color: @brand-orange !important; }
.text-blue        { color: @brand-blue !important; }
.text-green       { color: @brand-green !important; }

 //backgrounds
.bg-green  { background-color: @brand-green; }
.bg-orange { background-color: @brand-orange; }
.bg-blue   { background-color: @brand-blue; }
.bg-navy   { background-color: @brand-navy; }
.bg-grey   { background-color: @brand-grey; }
.bg-aqua   { background-color: @brand-aqua; }
.bg-purple { background-color: @brand-purple; }
.bg-pink   { background-color: @brand-pink; }
.bg-yellow { background-color: @brand-yellow; }
.bg-teal   { background-color: @brand-teal; }