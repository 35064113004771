.events-wrapper {
	a {
		color: @brand-navy !important;
	}
	padding-top: 40px;
}

.events-sidebar {
	.sidebar-section {
		margin-bottom: 20px;
		.sidebar-section-heading {
			color: @font-color-light;
			text-transform: uppercase;
		}
		.sidebar-section-links {
			padding-left: 0px;
			list-style: none;
			li {
				margin-top: 20px;
				&:hover {
					a {
						text-decoration: none;
						color: @brand-blue;
					}
				}
				a {
					color: @font-color;
				}
				a.active {
					color: @brand-blue;
				}
			}
		}
		//ECP Assets Single
		.tribe-events-single-section.tribe-events-event-meta {
			margin: 0px;
			//padding: 0 20px;
			background-color: transparent;
			border: none;
			word-wrap: break-word;
			font-size: inherit !important;
			.tribe-events-meta-group {
				float: none;
				width: 100%;
				padding: 0 0 10px 0;
				margin: 0;
				border-bottom: 1px solid @brand-grey;
				.tribe-events-single-section-title {
					.ff-quando;
					color: @brand-navy;
				}
				dl {
					dt,
					.tribe-events-event-categories {
						display: none;
					}
					dd {
						abbr {
							//font-size: 1.1em;
						}
						margin-bottom: 10px;
					}
				}
			}
			.tribe-events-venue-map {
				//display: none;
				width: 100%;
				margin: 20px 0;
				float: none;
			}
			.tribe-events-meta-group-organizer {
				//display: none;
			}
		}
	}
	hr {
		border-color: @brand-grey;
		margin: 20px 0;
	}
}

/**
 **Events Calendar Styling Overrides *******************************/

//wrapper
#tribe-events-content-wrapper {
	margin-bottom: 40px;
}
//Filter Box
#tribe-events-bar {
	margin-bottom: 0px;
}
//Navgiation
#tribe-bar-form {
	background-color: transparent;
	//.ff-quando;
	.tribe-bar-filters-inner {
		display: none;
	}
	#tribe-bar-views {
		left: auto;
		float: right;
		width: auto;
		.tribe-bar-views-inner {
			background-color: transparent;
			padding: 0px;
			label {
				display: inline-block;
			}
			ul {
				float: none;
				display: inline-block;
				width: auto;
				position: relative;
				background-color: transparent;
				li {
					.ff-quando;
					display: inline-block !important;
					border: 2px solid @brand-blue;
					margin-left: 10px;
					a {
						background-color: transparent;
						color: @brand-blue;
						border-radius: 0px;
						span {
							background-image: none;
							color: @brand-blue;
							padding: 0px;
						}
						&:hover {
							background-color: @brand-blue;
							span {
								color: #fff;
							}
						}
					}
				}
				li.tribe-bar-active {
					a {
						background-color: @brand-blue;
						color: #fff;
						span {
							color: #fff;
						}
					}
				}
			}
		}
	}
}
//Header
#tribe-events-header {

}

//Footer
#tribe-events-footer {
	margin-top: 20px;
}

//Header / Footer Nav
#tribe-events-header,
#tribe-events-footer {
	.tribe-events-sub-nav {
		.tribe-events-nav-previous,
		.tribe-events-nav-next {
			font-size: .8em;
			text-transform: uppercase;
		}
	}
}

//General
#tribe-events {
	.tribe-events-button  {
		background-color: #fff;
	}
}

//Calendar
.tribe-events-calendar {
	thead {
		th {
			color: #fff;
			background-color: @font-color;
		}
	}
	tbody {
		tr {
			td {
				.url {
					color: @brand-navy;
					.ff-montserrat;
					font-weight: 400;
				}
			}
		}
		.tribe-events-tooltip {
			border: 0px !important;
			box-shadow: 0px 0px 10px @font-color;
			.entry-title {
				.ff-montserrat !important;
				background-color: @font-color !important;
			}
			.tribe-events-event-thumb {
				img {
					width: 100px;
					height: auot;
					max-width: 100%;
				}
			}
			.tribe-events-arrow {
				display: none;
			}
		}
	}
}

//List
.tribe-events-loop.vcalendar {
	.tribe-events-list-separator-month {
		background-color: @font-color;
		color: #fff;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.type-tribe_events {
		.entry-title {
			a {
				color: @brand-navy;
			}
		}
		.tribe-events-event-meta {
			.time-details {
				span {
					font-size: 1.2em;
				}
			}
			.tribe-events-venue-details {
				color: @font-color-light;
				span {
					
				}
				address {
					
				}
				a {
					color: @brand-navy;
				}
			}
		}
		.tribe-events-content {
			p {

			}
			.tribe-events-read-more {
				color: @brand-navy;
			}
		}
	}
}

//Single Event
#tribe-events-content {
	.tribe-events-back {
		a {
			text-transform: uppercase;
			font-size: .8em;
		}
	}
	.entry-title {
		.ff-quando;
		font-size: 1.8em;
		font-weight: 400;
		color: @brand-navy;
		//margin-bottom: 0px;
	}
	.event-categories {
		margin-top: 10px;
		dt {
			display: none;
		}
		dd {
			a {
				display: inline-block;
				//color: @brand-aqua !important;
				color: #fff !important;
				background-color: @brand-aqua;
				border: 2px solid @brand-aqua;
				padding: 5px 10px;
				font-size: .8em;
				&:hover {
					background-color: @brand-aqua;
					color: #fff !important
				}
			}
		}
	}
	.tribe-events-schedule {
		margin-top: 10px;
		h3 {
			margin-top: 0px;
			text-transform: uppercase;
			color: @font-color-light;
			//.ff-quando;
			font-weight: 400;
		}
	}
}

@media screen and (max-width: 1024px) {
	.events-wrapper {
		padding-top: 30px;
	}
	#tribe-events {
		#tribe-events-bar {
			.tribe-bar-collapse {
				#tribe-bar-collapse-toggle {
					display: none;
				}
				#tribe-bar-views {
					width: 100%;
				}
			}
		}
		#tribe-events-content {
			#tribe-events-header,
			#tribe-events-footer {
				.tribe-events-sub-nav {
					li {
						a {
							background-color: #fff !important;
						}
					}
					.tribe-events-past {
						a { padding-left: 0px; }
					}
					.tribe-events-future {
						a { padding-right: 0px; }
					}
				}
			}
			.tribe-events-list-separator-month {
				margin-top: 15px;
			}
		}
	}
}