.internal-page {
	main {
		padding-top: 120px;
	}
	@media screen and (max-width: 1024px){
		main {
			padding-top: 75px;
		}
	}
	main.extended {
		padding-top: 0px;
	}
}

.page-title {
	background-color: @brand-navy;
	padding: 30px 20px;
	h1 {
		.ff-quando;
		font-size: 46px;
		color: #fff;
		margin: 0px;
		text-align: center;
	}
}
.page-title-extended {
	background-size: cover;
	background-position: center center;
	//padding-top: 120px;
	.overlay {
		//background-color: fade(#000, 20%);
		padding-top: 120px;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.65))); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* IE10+ */
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
	}
	h1 {
		.ff-montserrat;
		font-size: 46px;
		font-weight: 700;
		color: #fff;
		margin: 0px;
		padding: 140px 0;
		text-align: center;
	}
}
@media screen and (max-width: 768px) {
	.page-title-extended {
		.overlay {
			padding-top: 75px;
		}
	}
}

.extended-content {
	padding: 50px 40px;
	color: #fff;
	div {
		width: 1200px;
		max-width: 100%;
		margin: 0 auto;
	}
	blockquote {
		border: none;
	}
	.block-quote {
		text-align: center;
		.block-quote-content {
			color: #fff;
			font-size: 2em;
			padding-bottom: 30px;
		}
		.brand-divider {
			width: 100px;
			margin: 0 auto;
		}
		.block-quote-author {
			font-size: 1.2em;
			font-style: italic;
			font-weight: 700;
			margin-bottom: 0px;
			padding-top: 30px;
		}
	}
}

.page-content {
	padding-top: 40px;
	padding-bottom: 40px;
	.content-heading {
		.ff-quando;
		font-weight: 400;
		color: @brand-navy;
	}
	.lead {
		color: @font-color-light;
		line-height: 1.5em;
	}
	hr {
		//border-color: @font-color-light; 
	}
	p {
		margin: 30px 0;
		line-height: 1.5em !important;
	}
	a {
		color: @brand-blue;
	}
	ul,
	ol {
	  li {
	    margin: 5px 0;
	  }
	}
}

.sidebar-content {
	padding-top: 60px;
	padding-bottom: 40px;
	.button-sidebar {
		width: 100%;
		text-align: center;
		margin: 0 0 20px;
		span {
			margin-right: 10px;
			font-size: 26px;
			line-height: 10px;
			top: 3px;
			position: relative;
		}
	}
	p {
		margin-bottom: 20px;
	}
	a.sidebar-button {
		display: block;
		width: 100%;
		text-align: center;
	}

	//Donations Page Sidebar
	.sidebar-heading {
		h3 {
			color: @brand-navy;
			font-weight: 400;
		}
		p {
			color: @font-color-light;
		}
	}
	.donation-association {
		.amount {
			color: @brand-green;
			.ff-quando;
			font-size: 3.5em;
		}
		.description {
			font-weight: 400;
			//color: @font-color-light;
		}
		.brand-divider {
			background-color: @font-color-light;
		}
	}
	.sidebar-footer {
		padding-top: 40px;
		p {
			color: @font-color-light;
		}
	}
}

@media screen and (max-width: 1024px) {
	.page-content {
		.content-heading {
			font-size: 30px;
			word-break: break-word;
			max-width: 100%;
		}
	}
	.donate,
	.volunteer {
		.sidebar-content {
			display: none !important;
		}
	}
	.sidebar-content {
		.donation-association {
			.amount {
				font-size: 1.8em;
				margin-right: 5px;
				margin-bottom: 5px;
			}
			.description {
				font-size: 20px;
				margin: 0;
			}
		}
	}
	.donate {
		.sidebar-content {
			display: none;
		}
	}
}

.search {
	.page-title {
		display: block !important;
	}
	.news-container {
		padding: 20px 15px;
	}
	.search-results {
		border-right: 0px !important;
		.heading {
			margin-bottom: 40px;
			.search-terms	{
				color: @font-color-light;
			}
		}
		.news-article {
			margin-bottom: 40px;
		}
		.search-again {
			input {
				padding: 15px;
				height: auto;
				border-radius: 0px;
				width: 300px;
				//display: inline-block;
			}
			button {
				//display: inline-block;
			}
		}
	}
}


//Blog / News

.blog {
	.page-content {
		.blog-posts {
			article {
				h4 {
					color: @font-color-light;
				}
			}
		}
		.blog-pagination {
			margin-top: 30px;
		}
	}
}