/**
 ** Icon Counter *********************************************/
.icon-counter {
	text-align: center;
	padding: 20px;
	i {
		color: @brand-aqua;
		font-size: 4em;
	}
	span {
		color: @brand-navy;
		font-size: 5em;
		display: block;
	}
	p {
		color: @font-color-light;
		line-height: normal !important;
		margin: 0 !important;
	}
}


//Theme Settings
.counter-container {
	padding: 40px 20px;
	background-color: #fff;
}

@media screen and (max-width: 768px) {
	.counter-container {
		padding: 0px;
	}
	.icon-counter {
		i {
			font-size: 2em;
		}
		span {
			font-size: 2.5em;
		}
		p {

		}
	}
}