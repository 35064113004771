// Configuration
@import 'config/_variables';
@import 'config/_typography';

// Vendor
@import 'vendor/bootstrap/bootstrap';

// Mixins
@import 'mixins/_font-face';

//Modules
@import 'modules/_elements';
@import 'modules/_hero-slider';
@import 'modules/_hero-video';
@import 'modules/_page-loading';
@import 'modules/_rollover-box';
@import 'modules/_icon-counter';
@import 'modules/_gravity-forms';
@import 'modules/_selection-list';

// Layouts
@import 'views/layouts/_master';

// Theme Templates
@import 'views/_page';

// Partials
@import 'views/partials/_header';
@import 'views/partials/_footer';

// Pages
@import 'views/pages/_page-home';
@import 'views/pages/_page-events';
@import 'views/pages/_page-services';
@import 'views/pages/_page-careers';

// Animations
@import 'vendor/animate/animate';

//Custom Theme Styles
@import '_theme/_lys';

//Admin Styles
@import 'admin/_wp';
@import 'admin/_page-login';

@media screen and (max-width: 1024px) { //and (min-width: 769px) {

  //Tablet imports here
  @import 'views/partials/_header@1024';
  @import 'views/partials/_footer@768';

}

// Adaptive
@media screen and (max-width: 768px) {

  // Layouts
  @import 'views/layouts/_master@768';

  // Theme Templates
  @import 'views/_page@768';

  // Partials
  @import 'views/partials/_header@768';

  // Pages
  @import 'views/pages/_page-home@768';
}