.page-title, {
	background-color: @brand-navy;
	padding: 20px 20px;
	h1 {
		font-size: 26px;
		max-width: 100%;
	}
	display: none;
}


//Show Page title on events and news page
.post-type-archive-tribe_events,
.news {
	.page-title {
		display: block;
	}
}

.news {
	.news-container {
		padding: 20px;
	}
}

.page-title-extended {
	.overlay {
		//padding-top: 80px;
	}
	h1 {
		font-size: 36px;
		padding: 100px 0;
	}
}

.extended-content {
	padding: 30px 30px;
	.block-quote {
		.block-quote-content {
			font-size: 1.3em;
			padding-bottom: 10px;
		}
		.brand-divider {

		}
		.block-quote-author {
			font-size: 1em;
			padding-top: 15px;
		}
	}
}