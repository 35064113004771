.sl-header {
    max-width: 750px !important;

    .sl-title {

    }

    .sl-subtitle {
        font-size: 2rem;
        color: #000 !important;
    }
}

.selection-list {
    background-color: transparent;
    max-width: 1500px;
    margin: 0 auto;
    display: flex;

    .left {
        // background-color: orange;
        list-style: none;
        border-right: 1px solid black;
        height: 100%;

        li {
            padding: 15px 40px;
            font-size: 18px;

            &.active {
                text-decoration: underline;
                color: #1E366B;
                font-weight: 700;
            }
        }

        li:hover {
            background-color: lightgray;
            opacity: 0.7;
            cursor: pointer;
        }
    }

    select {
        display: none;
        max-width: 400px;
        padding: 5px;
        font-size: 1.25em;
    }

    .right {
        height: 100% !important;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        padding-left: 50px;

        :nth-child(1) {
            display: flex;
        }

        li {
            display: none;
            flex-direction: row;
            align-items: center;
            gap: 30px;
            margin-top: 100px;

            img {
                height: 300px;
            }
    
            div {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: left;
                text-align: left;
                gap: 25px;
                max-width: 400px;

                a {
                    border: 3px solid #9DBA07;
                    border-top-width: 4px;
                    width: 200px;
                    text-align: center;
                    padding: 5px;
                    color: #9DBA07;
                    font-size: 2rem;
                    font-weight: 700;
                }

                a:hover {
                    background-color: #9DBA07;
                    color: #fff;
                    text-decoration: none;
                    border-bottom: 2px solid forestgreen;
                }
            }
        }
    }
}

.active {
    &.sl-title-aqua { color: @brand-aqua !important; }
    &.sl-title-blue { color: @brand-blue !important; }
    &.sl-title-green { color: @brand-green !important; }
    &.sl-title-yellow { color: @brand-yellow !important; }
    &.sl-title-orange { color: @brand-orange !important; }
    &.sl-title-purple { color: @brand-purple !important; }
    &.sl-title-pink { color: @brand-pink !important; }
    &.sl-title-teal { color: @brand-teal !important; }
    &.sl-title-tangerine { color: @brand-tangerine !important; }
}

.sl-learn-more-aqua { 
    color: @brand-aqua !important;
    border-color: @brand-aqua !important;

    &:hover {
        background-color: @brand-aqua !important;
        color: #fff !important;
    }
 }
.sl-learn-more-blue {
    color: @brand-blue !important;
    border-color: @brand-blue  !important;

    &:hover {
        background-color: @brand-blue !important;
        color: #fff !important;
    }
}
.sl-learn-more-green {
    color: @brand-green !important;
    border-color: @brand-green !important;


    &:hover {
        background-color: @brand-green !important;
        color: #fff !important;
    }
}
.sl-learn-more-yellow { 
    color: @brand-yellow !important;
    border-color: @brand-yellow !important;

    &:hover {
        background-color: @brand-yellow !important;
        color: #fff !important;
    }
}
.sl-learn-more-orange {
    color: @brand-orange !important;
    border-color: @brand-orange !important;

    &:hover {
        background-color: @brand-orange !important;
        color: #fff !important;
    }
}
.sl-learn-more-purple {
    color: @brand-purple !important;
    border-color: @brand-purple !important;

    &:hover {
        background-color: @brand-purple !important;
        color: #fff !important;
    }
}
.sl-learn-more-pink {
    color: @brand-pink !important;
    border-color: @brand-pink !important;

    &:hover {
        background-color: @brand-pink !important;
        color: #fff !important;
    }
}
.sl-learn-more-teal {
    color: @brand-teal !important;
    border-color: @brand-teal !important;

    &:hover {
        background-color: @brand-teal !important;
        color: #fff !important;
    }
}
.sl-learn-more-tangerine {
    color: @brand-tangerine !important;
    border-color: @brand-tangerine !important;

    &:hover {
        background-color: @brand-tangerine !important;
        color: #fff !important;
    }
}

/* Hover colors */
*:hover {
    &.hover-aqua { color: @brand-aqua !important; }
    &.hover-blue { color: @brand-blue !important; }
    &.hover-green { color: @brand-green !important; }
    &.hover-yellow { color: @brand-yellow !important; }
    &.hover-orange { color: @brand-orange !important; }
    &.hover-purple { color: @brand-purple !important; }
    &.hover-pink { color: @brand-pink !important; }
    &.hover-teal { color: @brand-teal !important; }
    &.hover-tangerine { color: @brand-tangerine !important; }
}

/* Tabletish size */
@media (max-width: 1200px) {
    .selection-list {
        justify-content: center !important;
        align-items: center !important;
        max-width: 800px;

        .left {
            li {
                padding: 15px 40px;
                font-size: 18px;
            }
        }

        .right {
            width: 100%;
            li {
                margin-top: 0;
                flex-direction: column;
                width: fit-content !important;
            }
        };
    }
}

/* Phone size */
@media (max-width: 800px) {
    .selection-list {
        flex-direction: column;

        .left {
            display: none;
        }

        select {
            display: block;
            margin: 25px;
        }

        .right {
            padding: 20px;
            justify-content: center;
            width: 100%;

            li {
                margin-top: 0;
                flex-direction: column;
            }
        };
    }
}