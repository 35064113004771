footer {
	.footer {
		padding: 20px 0;
		.footer-columns {
			ul {
				li {
					a {

					}
				}
			}
			.col-xs-3 {
				padding: 0px;
			}
			.icon {
				padding: 0px;
				svg {
					width: 100%;
					height: auto;
				}
			}
			.footer-nav {

			}
			.footer-primary {

			}
			.address-container {

			}
			.media-toolkit {
				padding: 10px 0;
				list-style: none;
				text-align: center;
				li {
					display: inline-block;
					padding: 0 10px;
					a {
						color: @brand-navy;
					}
				}
			}
		}
	}
}