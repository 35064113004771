.hero-slider {
	.carousel-inner {
		height: 100% !important;
		.item {
			height: 100% !important;
			img {
				min-width: 100% !important;
				min-height: 100% !important;
				max-width: none !important;
			}
		}
	}
}

.hero-slider.full-height-hero {

}

//Tablets Styles
@media screen and (max-width: 1024px) and (min-width: 769px){
	.hero-slider {

	}
}

//Phone Styles
@media screen and (max-width: 768px){
	.hero-slider {
		//height: 300px !important;
	}
}

#homePageSlider {
	.carousel-inner {
		.item {
			.carousel-caption {
				right: auto;
				left: 15%;
				top: 40%;
				bottom: auto;
				padding: 20px 30px;
				text-align: left;
				background-color: fade(#fff, 80%);
				h2 {
					text-transform: uppercase;
					margin: 0;
					font-size: 28px;
				}
				h1 {
					font-size: 40px;
					font-weight: 500;
					margin: 10px 0 30px;
					.ff-quando;
					color: @font-color;
					line-height: 50px;
				}
				a {
					color: #fff;
					padding: 10px 40px;
					display: inline-block;
					.ff-quando;
					font-size: 1.8em;
					position: absolute;
					bottom: -30px;
				}
			}
		}
	}
	.carousel-control {
		.glyphicon {
			transform: scale(1.5, 2) !important;
		}
	}
	.carousel-indicators {
		li.active {
			transition: all .3s;
			background-color: @brand-green;
			border-color: @brand-green;
		}
	}
}

#homePageSliderMobile {
	display: none;
	height: 600px;
	background-size: cover;
	background-position: center center;
	width: 100%;
	margin: 0;
	.outer {
		padding-top: 75px;
		width: 100%;
		height: 100%;
		display: table;
		.inner {
			padding: 20px;
			height: 100%;
			display: table-cell;
			vertical-align: middle;
			.overlay {
				background-color: fade(#fff, 80%);
				padding: 20px;
				h2 {
					text-transform: uppercase;
					margin: 0;
					font-size: 24px;
				}
				h1 {
					font-size: 30px;
					font-weight: 500;
					margin: 10px 0 30px;
					.ff-quando;
					color: @font-color;
					line-height: 40px;
				}
				a {
					color: #fff;
					padding: 10px 40px;
					display: inline-block;
					.ff-quando;
					font-size: 1.8em;
					//position: absolute;
					//bottom: -30px;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) and (min-width: 769px){
	#homePageSliderMobile {
		.outer {
			width: auto;
			.inner {
				width: auto;
				.overlay {
					width: auto;
				}
			}
		}
	}
}


@media screen and (max-width: 1024px) { // and (min-width: 769px){
	#homePageSliderMobile {
		display: block !important;
	}
	#homePageSlider {
		display: none !important;
		// height: 420px !important;
		.carousel-inner {
			.item {
				img {
					// left: 50%;
					// margin-left: -50%;
					max-height: 100%;
					width: auto;
				}
				.carousel-caption {
					//top: 25%;
					top: 100px;
					left: 20px;
					right: 20px;
					bottom: 50px;
					padding: 10px 15px;
					// br {
					// 	display: none;
					// }
					h2 {
						font-size: 16px;
					}
					h1 {
						font-size: 20px;
						line-height: 32px;
					}
					a {
						margin: 0 auto;
						font-size: 20px;
						padding: 10px 28px;
						width: 190px;
						display: block;
						text-align: center;
						position: initial;
						margin-top: -15px;
					}
				}
			}
		}
		.carousel-control {
			display: none;
		}
		.carousel-indicators {

		}
	}
}

@media screen and (max-width: 600px) {
	#homePageSliderMobile {
		.outer {
			.inner {
				.overlay {
					h2 {
						font-size: 20px;
					}
					h1 {
						font-size: 30px;
						margin-bottom: 20px;
					}
					a {
						font-size: 20px;
						margin-top: 0px;
					}
				}
			}
		}
	}
}