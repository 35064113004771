nav.navbar-fixed {
	position: fixed;
	width: 100%;
	z-index: 100;
}
nav.navbar-default {
	border-bottom: none !important;
	border-radius: 0px;
	box-shadow: 0px 2px 15px fade(#000, 10%);
	//background-color: fade(#fff, 90%);
	background-color: #fff;
	margin-bottom: 0px !important;
	.visible-mobile {
		display: none !important;
	}
	.navbar-retract,
	.nav-backdrop,
	.mobileSearch {
		display: none;
	}
	.navbar-collapse {
		padding-bottom: 20px;
		ul {
			li {
				&:hover {
					ul {
						//display: block;
					}
				}
				a {
					font-weight: 300;
					color: @brand-navy;
					&:hover {
						color: @brand-aqua;
					}
				}
				ul {
					&:hover {
						//display: block;
					}
				}
				.mobile-indicators {
					display: none;
					// float: right;
				}
			}
			.active {
				a {
					background-color: transparent !important;
					color: @brand-aqua;
				}
			}
		}
	}

	.navbar-header {
		@media screen and (max-width: 1025px) {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;
			.navbar-brand {
				svg {
					margin-top: 0;
					width: 60%;	
				}
			}
		}
		@media screen and (max-width: 1087px) {
			.navbar-brand {
				width: 350px;
			}
		}
	}

	.navbar-brand {
		//float: left;
		padding: 18px 20px;
		svg {
			@media screen and (max-width: 1200px) {
				height: 65px;
				margin-top: 15px;
			}
			@media screen and (max-width: 1092px) {
				height: 45px;
				width: 60%;
				margin-top: 28px;
			}
			// @media screen and (max-width: 1024px) {
			// 	margin-top: 0;
			// }
			transition: all .5s ease;
			.st0 {
			stroke: none;
			fill: @brand-navy;
			}
			width: 100%;
			max-height: 75px;

			g {
				path {
					fill: @brand-navy;
					opacity: 1;
				}
			}
		}
	}
	@media screen and (min-width: 1025px) {
		.navbar-brand {
			//padding: 25px 20px;
		}
	}

	.navbar-primary {
		display: block;
		.ff-quando !important;
		font-size: 1.4em;
		clear: right;
		li {
			//margin-top: 10px;
			a {
				padding: 15px 25px;
				&:hover {
					color: @brand-aqua;
				}
			}
		}
		
		.showChildren {
			/*
			 * Add persitent active color to 
			 * primary nav when children are displayed
			 */
		}

		.donate {
			background-color: @brand-yellow;
			a {
				&:hover {
					color: #fff !important;
				}
			}
		}
		.donate.active {
			a {
				color: #fff;
			}
		}
	}

	.navbar-secondary {
		display: block;
		font-size: .8em;
		li {
			a {
				padding: 20px 18px 10px;
			}
		}
	}

	.navbar-children {
	    //-webkit-transition-duration: .5s; /* Safari */
	    animation-duration: .2s !important;
		display: none;
		z-index: 0;
		min-width: 200px;
		position: absolute;
		top: 70px;
		list-style: none;
		margin: 0;
		padding: 10px 0 20px;
		background-color: @brand-blue;
		box-shadow: 0px 5px 20px fade(#333, 50%);
		li {
			float: none;
			height: auto;
			a {
				display: block;
				float: none;
				height: auto;
				.ff-montserrat;
				color: #fff !important;
				font-size: .65em;
				line-height: normal;
				padding: 10px 20px;
				&:hover {
					color: @brand-aqua !important;
					text-decoration: none;
				}
			}
		}
	}

	.navbar-children.visible {
		display: block !important;
	}


	#search-slideout {
		display: none;
		padding: 10px 20px;
		margin: 0px;
		background-color: #fff;
		position: absolute;
		top: 0;
		width: 100%;
		min-height: 50px;
		input {
			border: none !important;
			background-color: transparent !important;
			color: @brand-navy !important;
			font-size: 24px;
			font-weight: 300;
			padding: 0px !important;
			outline: 0;
			text-transform: uppercase;
			&:active,
			&:focus {
				outline: 0 !important;
			}
		}
		::-webkit-input-placeholder {
			color: @brand-navy;
			text-transform: uppercase;
			font-weight: 300;
		}
		:-moz-placeholder { /* Firefox 18- */
		   color: @brand-navy;  
		}
		::-moz-placeholder {  /* Firefox 19+ */
		   color: @brand-navy;  
		}
		:-ms-input-placeholder {  
		   color: @brand-navy;  
		}
		#searchsubmit {
			display: none;
		}
		button {
			position: absolute;
			background-color: transparent;
			border: none;
			color: @brand-navy;
			right: 0px;
			top: 10px;
			font-size: 24px;
		}
	}

}

//Squishy Header
nav.navbar-fixed.squishy {
	//background-color: fade(#fff, 80%) !important;
	.container-fluid {
		.navbar-brand {
			padding-top: 10px;
			svg {
				height: 60px;
				g {
					path {

					}
				}
			}
		}
		.navbar-collapse {
			padding-bottom: 10px;
			ul.navbar-primary {
				> li {
					a {
						font-size: .8em;
						padding: 10px 15px;
					}
				}
			}
			ul.navbar-secondary {
				li {
					a {
						padding-top: 10px;
					}
				}
			}
			.navbar-children {
				transition: none !important;
				top: 50px;
				font-size: .8em;
			}
		}
	}
	.container-fluid * {
		transition: all .5s;
	}
}

@media screen and (max-width: 1150px) and (min-width: 1025px) {
	ul.navbar-primary {
		> li {
			> a {
				font-size: .9em;
			}
		}
	}
}