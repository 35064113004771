footer {
	font-size: .9em;
	.footer {
		background-color: @brand-grey;
		padding: 40px 20px;
		> div {
			// padding: 20px;
		}
		.footer-columns {
			.icon {
				padding: 0 20px 0 0;
				svg {
					path,
					rect {
						fill: @brand-navy;
					}
					line {
						stroke: @brand-navy;
					}
				}
			}
			.footer-nav {
				ul {
					list-style: none;
					padding: 0px;
					li {
						a {
							color: @brand-navy;
							padding: 8px 0;
							display: block;
						}
					}
				}
			}
			.footer-primary {
				li {
					a {
						font-weight: 700;
					}
				}
			}
			.address-container {
				.col-sm-7,
				.col-sm-5 {
					padding: 0px;
				}
				h4 {
					.ff-quando;
					font-size: 1.1em;
					font-weight: 400;
					color: @brand-navy;
					//margin: 0;
				}
				ul {
					list-style: none;
					margin: 10px 0;
					padding: 0px;
					color: @brand-navy; 
				}
				img {
					max-width: 100%;
					height: auto;
				}
			}
		}
		.footer-social {
			text-align: center;
			a.social {
				line-height: 1.8em;
				font-size: 1.7em;
				width: 43px;
				height: 43px;
				text-align: center;
				border-radius: 100%;
				color: #fff;
				margin: 1px;
				display: inline-block;
				&:hover {
					background-color: @brand-yellow;
				}
			}
			.facebook { background-color: @color-facebook; }
			.twitter { background-color: @color-twitter; }
			.linkedin { background-color: @color-linkedin;	}
			.instagram {background-color: @color-instagram; }
		}
	}
	.socket {
		background-color: @brand-blue;
		padding: 20px;
		color: #fff;
		text-align: center;
	}
}

@media screen and (max-width: 1024px) {
	footer {
		//margin-top: 20px;
	}
}