.login {
	padding: 20px;
	background-color: #fff;
	.ff-montserrat;
	#login {
		max-width: 100%;
		h1 {
			a {
				background-image: url(/assets/images/logo.png) !important;
				background-size: 80%;
				width: 100%;
			}
		}
	}
	#loginform,
	#registerform,
	#lostpasswordform {
		padding: 0px;
		border: none;
		border-radius: 5px;
		max-width: 100%;
		width: 320px;
		box-shadow: none;
		background-color: transparent;
		p {
			label {
				.ff-montserrat;
				color: @font-color-light;
				font-size: 1.1em;
				font-weight: 400;
				text-transform: uppercase;
				text-shadow: none;
				width: 100%;
				input[type=text],
				input[type=password],
				input[type=email] {
					.ff-montserrat;
					background-color: #fff !important;
					border-radius: 3px;
					padding: 10px;
					font-size: 1em;
					width: 100%;
					&:focus {
						background-color: #fff;
					}
				}
			}
		}
		#user_pass {
			margin-bottom: 15px;
		}
		p.forgetmenot {
			margin-top: 10px;
			label {
				overflow: visible;
				.ff-montserrat;
				font-size: .9em;
				text-transform: capitalize;
				text-shadow: none;
				input[type=checkbox]{
					height: 15px;
					width: 15px;
					border-radius: 2px;
					margin-top: -1px;
				}
			}
		}
		p.submit {
			//margin-top: 15px;
			input[type=submit] {
				box-shadow: none;
				background-color: @brand-yellow;
				color: @brand-navy;
				border: none;
				.ff-quando;
				font-weight: bold;
				padding: 0px 15px 2px;
				border: 2px solid @brand-yellow;
				transition: all .5s;
				&:hover {
					background-color: transparent;
					//color: @brand-yellow;
				}
			}
		}
	}
	p#nav,
	p#backtoblog {
		//padding: 0;
		a {
			&:hover {
				color: @brand-aqua;
			}
		}
	}
}