/**
 ** Service Pages **********************************************/
.page-template-page-service {
	main {
		padding-top: 0px;
	}
}
.service (@color) {
	.service-header {
		background-size: cover;
		background-position: top center;
		width: 100%;
		padding: 0px;
		.service-header-overlay {
			position: relative;
			//background-color: fade(@color, 50%);
			background-color: fade(#000, 20%);
			padding-top: 120px;
			.service-header-title {
				text-align: center;
				margin: 0 auto;
				max-width: 100%;
				width: 950px;
				padding: 180px 20px;
				color: #fff;
				.ff-quando;
				font-size: 3.5em;
			}
		}
		.service-header-overlay.no-color {
			background-color: fade(#000, 20%);
		}
	}
	.service-navigation {
		width: 100%;
		background-color: @brand-grey;
		ul {
			text-align: center;
			//display: inline-block;
			list-style: none;
			margin: 0 auto;
			padding: 30px 20px;
			width: 100%;
			li {
				width: 12.5%;
				display: inline-block;
				// margin: 0 20px;
				padding: 0 20px;
				a {
					font-size: 16px;
					color: @font-color-light;
				}
				&:hover {
					a {
						color: @color;
						text-decoration: none;
					}
				}
			}
			.active {
				a {
					color: @color;
				}
			}
		}
	}
	.hero-quote {
		width: 100%;
		padding: 0px;
		//height: 300px;
		.quote-content{
			height: 450px;
			padding: 40px;
			background-color: @font-color;
			text-align: center;
			color: #fff;
			.quote {
				font-size: 2em;
			}
			.brand-divider {
				background-color: @color;
				//width: 120px;
			}
			.author {
				font-style: italic;
			}
		}
		.quote-image {
			background-size: cover;
			background-position: center center;
			height: 450px;
		}
	}

	.container {
		padding: 60px 20px 40px;
	}

	.service-sidebar {
		.mobile-dropdown {
			button {
				background-color: @color;
			}
			#close-dropdown {
				color: @color;
				background-color: transparent;
				border: none;
				margin: 0;
				padding: 0;
				span {
					display: block;
				}
			}
		}
		.sidebar-heading {
			color: @color;
			margin-bottom: 10px;
			font-weight: 700;

		}
		> ul {
			padding-left: 0px;
		}
		ul {
			list-style-type: none;
			padding: 0 0 10px 0;
			margin: 0;
			border-bottom: 1px solid #000;

			li {
				a {
					font-weight: 500;
					padding: 5px 0;
					border-bottom: none;
				}
			}
		}
		.current_page_item {
			//color: @color;
			> a {
				color: @color;
			}
		}
		a {
			display: inline-block;
			padding: 15px 0;
			border-bottom: 1px solid #000;
			width: 100%;
			font-weight: 700;
			font-size: 1em;
			color: @font-color;
			&:hover {
				color: @color;
				text-decoration: none;
			}

			&:last-child {
				border-bottom: none;
			}

			&.has-children {
				border-bottom: none;
				margin: 0;
				padding: 10px 0;
			}
		}
	}

	.service-content.page-content {
		padding-top: 0px;
		h1 {
			margin-top: 0px;
		}
		h1, h2, h3, h4, h5, h6, a {
			color: @color;
		}
		.dropdown-section {
			> a {
				color: @color;
				.fa-angle-up {
					color: @color;
				}
			}
		}
	}

@media screen and (max-width: 1024px){
	.service-header {
		.service-header-overlay {
			padding-top: 75px;
			.service-header-title {
				padding: 100px 20px;
				font-size: 2.6em;
				max-width: 100%;
				overflow: hidden;
			}
		}
	}
	.service-navigation {
		display: none;
		ul {
			padding: 15px 0;
			li {
				margin: 0;
				box-sizing: border-box;
				width: 12.5% !important;
				padding: 0 15px;
				a {
					font-size: 12px;
				}
			}
		}
	}
	.hero-quote {
		.quote-content {
			padding: 20px;
			height: auto;
			.quote {
				font-size: 1.2em;
			}
		}
		.quote-image {
			height: 200px;
		}
	}
	.container {
		padding: 0 0 40px;

		.service-sidebar {
			width: 100%;
			display: block;
			padding: 0px;
			.mobile-dropdown {
				button {
					padding: 10px;
					margin-bottom: 0px;
				}
				.dropdown-content {
					background-color: #eee;
					padding: 20px;
					ul {
						padding-left: 10px;
						li {
							padding: 5px 0;
						}
					}
				}
			}
		}

		.service-content.page-content {
			padding: 20px 30px;
		}
	}
}

}

@media screen and (max-width: 1024px) and (min-width: 769px){
	.hero-quote {
		//height: 250px;
		.quote-content {
			padding: 20px;
			//height: 100% !important;
			.quote {
				font-size: 1.2em;
			}
		}
		.quote-image {
			height: 200px !important;
			background-position: center center !important;
		}
	}
	.service-sidebar {
		margin-top: 20px;
	}
}

@media screen and (max-width: 768px) {
	.service-navigation {
		display: none !important;
	}
}


//Color Options
.service-accent-aqua 		{ .service (@brand-aqua); 	}
.service-accent-blue 		{ .service (@brand-blue); 	}
.service-accent-green 	{ .service (@brand-green); 	}
.service-accent-yellow 	{ .service (@brand-yellow); }
.service-accent-orange 	{ .service (@brand-orange); }
.service-accent-purple 	{ .service (@brand-purple); }
.service-accent-pink 		{ .service (@brand-pink); 	}
.service-accent-teal 		{ .service (@brand-teal); 	}
.service-accent-tangerine { .service(@brand-tangerine); }


//Testing
#service-toggle-overlay {
	color: #fff;
	border: none !important;
	background-color: fade(#000, 10%);
	border-radius: 3px;
	position: absolute;
	bottom: 0;
	left: 0;
	&:hover {
		background-color: fade(#000, 40%);
	}
}