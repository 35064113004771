.ff-lato {
  font-family: 'lato', 'Helvetica Neue', Arial, sans-serif;
}

.ff-montserrat {
	font-family: 'montserrat', 'lato', 'Helvetica Neue', sans-serif;
}

.ff-quando {
	font-family: 'quando', serif;
	// font-family: 'baskerville', serif; //font-weight: 500;
	// font-family: 'garamond';
	// font-family: 'lusitana';
	//font-family: 'source serif pro';
}

.ff-body {
  .ff-montserrat;
}

.ff-header {
  .ff-montserrat;
  font-weight: 700;
}