html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  .ff-body;
  font-size: @font-size;
  line-height: @font-line-height;
  color: @font-color;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  .ff-header;
}

a, 
button,
input {
  outline: 0 !important;
  &:active,
  &:focus {
    outline: 0 !important;
  }
}

hr {
  color: #ccc !important;
}

.fullwidth {
  margin: 0px !important;
  padding: 0px !important;
}

.image-with-caption {
  img {
    width: 100%;
    height: auto;
  }
  p.caption {
    color: @font-color-light;
    font-style: italic;
    font-size: .9em;
    margin-top: 10px;
  }
}

.arrow-up (@color, @side, @size) {
  width: 0; 
  height: 0; 
  margin-left: -@side;
  border-left: @side solid transparent;
  border-right: @side solid transparent;
  border-bottom: @size solid @color;
}

.arrow-down (@color, @side, @size) {
  width: 0; 
  height: 0; 
  margin-left: -@side;
  border-left: @side solid transparent;
  border-right: @side solid transparent;
  border-top: @size solid @color;
}

.arrow-right (@color, @side, @size) {
  width: 0; 
  height: 0; 
  border-top: @side solid transparent;
  border-bottom: @side solid transparent;
  border-left: @size solid @color;
}

.arrow-left (@color, @side, @size) {
  width: 0; 
  height: 0; 
  border-top: @side solid transparent;
  border-bottom: @side solid transparent; 
  border-right: @size solid @color; 
}

//hidden class
.hideMe {
  display: none !important;
}

/**
 **Style Guide Assets *************************************/

.page-content {
  
  > h1 {
    font-size: 35px;
    font-family: 'quando';
    color: @brand-navy;
  }

  > h2 {
    font-size: 30px;
    font-family: 'montserrat';
    color: #444;
  }

  > h3 {
    font-size: 25px;
    font-family: 'montserrat';
    color: @brand-navy;
  }

  > h4 {
    font-size: 20px;
    font-family: 'montserrat';
    color: @brand-blue;
  }

  .lead {
    color: @font-color-light;
  }

  > a {
    color: @brand-blue;
  }

  > hr {
    border-color: @brand-grey;
  }

}

@media screen and (max-width: 1024px) {
.col-md-8 {
  width: 100% !important;
}
.page-template-page-services,
.page-template-pages-service-child {
  .container {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .page-content {
    padding-top: 20px !important;
    padding-bottom: 0px !important;
  }
  .sidebar-content {
    padding-top: 0px !important;
    padding-bottom: 20px !important;
  }
}
}

/** end style guide assets */

.page-template-page-board {
  .donation-container {
    display: none !important;
  }
}

/**
 ** Archive Navigation Styles */
nav.blog-pagination {
  width: 100%;
  margin-bottom: 20px;
  #page-previous {
    float: left;
  }
  #page-next {
    float: right;
  }
  a {
    display: block;
    &:hover {
      text-decoration: none;
    }
  }
}